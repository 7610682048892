.badge-container {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  align-items: center;
}

.tooltip-content {
  display: flex;
  flex-direction: column;
  padding: 16px;
  max-height: 50vh;
}

.tooltip-header {
  color: #374151;
  margin-bottom: 12px;
}

.tooltip-badges {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  overflow-y: auto;
}

.badge-text {
  color: var(--blue-color-primary);
  font-weight: 700;
}

.badge-text-summary {
  color: var(--blue-color-primary);
  background-color: #fafafa;
}
