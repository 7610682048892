.container {
  width: 98%;
  margin: auto;
}

.no-alerts-text {
  display: flex;
  justify-content: center;
  gap: 5px;
  margin-top: 5rem;
}
