.table-container {
  width: 100%;
  height: 95%;
  padding: 10px;
  margin: auto;
  color: var(--blue-color-primary);
}

.form-to-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.delete-icon-container {
  flex: 0.1;
  display: flex;
  align-items: center;
  opacity: 0;
  cursor: pointer;
}

.show-delete-icon {
  opacity: 1;
}

.time-container {
  flex: 0.9;
}

.recurrence-cell {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.recurrence-icon {
  display: flex;
  align-items: center;
}

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  display: inline-block;
  text-overflow: ellipsis;
}

.no-rows {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100%;
  color: var(--blue-color-primary);
}

.loading {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  color: var(--blue-color-primary);
}

.one-time {
  font-size: 12px;
  font-weight: 400;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.active-icon-container {
  position: absolute;
  left: 0;
}
