.children-container {
  max-height: 80vh;
  padding: 0px;
  margin: 0px;
}

.title {
  padding: 28px;
  padding-bottom: 15px;
  text-align: center;
}

.full-screen-title {
  padding-left: 40px;
  margin-top: 28px;
  border-bottom: none;
  text-align: left;
}
