.edit-button {
  display: flex;
  font-size: 10px;
  font-family: var(--font-family);
  flex-direction: column;
  align-items: normal;
  justify-content: space-around;
  text-transform: none;
}

.edit-button-icon {
  margin-top: -12px;
}

@media only screen and (max-width: 1600px) {
  .edit-button-icon {
    margin-top: -3px;
    margin-left: 6px;
  }
}

.edit-button-text {
  color: var(--blue-color-primary);
}
