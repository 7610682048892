.rounded-button {
  border-radius: var(--border-radius-button);
  text-align: center;
  text-transform: capitalize;
  display: flex;
  cursor: pointer;
}

.rounded-button:disabled {
  background-color: var(--disable-color);
  color: var(--button-text-color);
}
