.dialog-title {
  display: flex;
  align-items: flex-start;
}

.dialog-content-container {
  width: 70vw;
  max-width: 1000px;
}

.alert-properties {
  display: flex;
  flex-direction: column;
  height: 160px;
  align-items: flex-start;
  justify-content: space-between;
  margin: 0 23px;
}

.alert-property-label {
  font-size: 16px;
}

.alert-property-value {
  font-size: 14px;
}

.transitions-table-container {
  width: 100%;
  height: 300px;
  overflow-y: auto;
}

.transitions-table-header {
  color: #1377e7;
  font-size: 16px;
  width: fit-content;
  margin: 32px 0 0 23px;
}

.transitions-table-header-underline {
  height: 5px;
  background-color: #1377e7;
  border-radius: 50px;
}
