.form {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  justify-content: center;
  gap: 11px;
  height: 100%;
}

.full-height {
  height: 100%;
}

.item {
  width: 90%;
}

.bottom-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  gap: 11px;
  width: 100%;
  height: 100%;
  margin-bottom: 12px;
}

.elevation-row {
  display: flex;
  flex-direction: row;
  gap: 5px;
  columns: 10;
  width: 90%;
}

.elevation-value {
  flex: 6;
}

.elevation-direction {
  flex: 4;
}

.input-text-size {
  font-size: 14px;
  color: var(--blue-color-primary);
}

@media screen and (max-width: 1600px) {
  .input-text-size {
    font-size: 10px;
  }

  .elevation-value {
    flex: 4;
  }

  .elevation-direction {
    flex: 6;
  }

  .form {
    gap: 15px;
  }
}
