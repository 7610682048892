.error-icon {
  position: absolute;
  top: -3px;
  left: 25px;
  fill: #3c3c3c;
  width: 11px;
  height: 11px;
}

@media screen and (max-width: 1600px) {
  .error-icon {
    left: 12px;
    width: 10px;
    height: 10px;
  }
}
