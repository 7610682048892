.container {
  display: flex;
  align-items: center;
  width: 100%;
}

.icon-container {
  flex: 0.5;
  display: flex;
  justify-content: flex-end;
}

.severity-container {
  flex: 0.5;
  display: flex;
  align-items: flex-start;
  margin-left: 0.2rem;
}
