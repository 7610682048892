.toast-container {
  background-color: #595959;
  border-radius: 30px;
  display: flex;
  flex-direction: column;
  line-height: 23px;
  justify-content: space-between;
  padding: 20px;
  gap: 20px;
  letter-spacing: 0.05em;
  text-align: center;
}

.toast-title {
  font-size: 18px;
  text-align: center;
  line-height: 23px;
  white-space: "pre-line";
}

.toast-time-message {
  color: white;
  font-size: 16px;
  font-weight: 400;
}

.success-toast {
  color: #8ee6a8;
}

.fail-toast {
  color: #ff8795;
}

.toast-btn {
  display: flex;
  width: 100%;
  justify-content: center;
  font-size: 16px;
}
