.form-container {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.row {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.column {
  flex: 1;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.error-message {
  color: red;
  margin-top: 5px;
  text-align: center;
}
