.min-max-input {
  margin: 0px;
  width: 100%;
  text-align: center;
  border: var(--blue-color-primary);
  border-radius: 3px;
  color: var(--blue-color-primary);
  padding: 0.2rem;
}
.min-max-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-width: 40px;
  height: 100%;
  padding: 0px;
  padding-left: 0.5rem;
}
