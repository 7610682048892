.center {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  display: inline-block;
  text-overflow: ellipsis;
}
