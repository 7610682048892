.column {
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-top: 30px;
  gap: 20px;
}

.dropdown {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
}
