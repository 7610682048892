.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: var(--background-color);
}

.title {
  margin: 1rem;
  color: var(--error-color);
  font-size: 24px;
  font-weight: 500;
}

.message {
  text-align: left;
  margin: 1rem 0;
  color: var(--blue-color-primary);
  font-size: 14px;
  font-weight: 400;
  border: var(--card-border);
  padding: 13px;
  border-radius: 13px;
  min-height: 100px;
}

.suggestion {
  font-weight: 400;
}

.logout-button {
  margin: 1rem 0 1.2rem 0;
}
