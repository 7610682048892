body {
  color: var(--blue-color-primary);
  font-family: var(--font-family);
  font-style: var(--font-style);
  font-weight: var(--font-weight);
}

.h1 {
  font-size: 30px;
  font-weight: 350;
}

.h2 {
  font-size: 20px;
}
.h3 {
  font-size: 20px;
  font-weight: 500;
}

.h4 {
  font-size: 18px;
}

.h5 {
  font-size: 14px;
}
.h6 {
  font-size: 14px;
  font-weight: 500;
}

.h7 {
  font-size: 12px;
}
.h8 {
  font-size: 10px;
}

@media screen and (max-width: 1600px) {
  .h1 {
    font-size: 20px;
    font-weight: 350;
  }
  .h2 {
    font-size: 16px;
  }
  .h3 {
    font-size: 16px;
    font-weight: 500;
  }
  .h4 {
    font-size: 14px;
  }
  .h5 {
    font-size: 12px;
  }
  .h6 {
    font-size: 12px;
    font-weight: 500;
  }
  .h7 {
    font-size: 10px;
  }
  .h8 {
    font-size: 8px;
  }
}
