.alert {
  margin-right: 1rem;
  z-index: 1;
  position: absolute;
  width: 90%;
}

.row {
  width: 100%;
  display: flex;
  z-index: 1;
}

.leftSide {
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
}

.rightSide {
  display: inline-flex;
  width: 50%;
  justify-content: flex-end;
  margin-right: 2.5rem;
}

.commands {
  text-align: end;
}

.alert-container {
  width: 100%;
}
