.center {
  margin: 1rem;
  overflow: hidden;
  width: 98%;
  height: 100%;
}
.add-report {
  display: flex;
  flex-direction: flex-start;
  margin: 1rem;
  width: 150px;
}
