.list-container {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  height: calc(100% - 40px);
  list-style-type: none;
  padding: 0%;
  margin: 20px 0px;
}

@media only screen and (max-width: 1600px) {
  .list-container {
    margin: 15px 0px;
    height: calc(100% - 30px);
  }
}
