.img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.export-button-wapper {
  width: 90%;
  display: flex;
}
.export-button {
  align-self: flex-start;
}
