.container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  color: var(--blue-color-primary);
}

.tag-name {
  text-wrap: wrap;
  text-align: left;
}
