.skeleton path,
.skeleton rect {
  animation: pulseColor 2s infinite ease-in-out;
}

@keyframes pulseColor {
  0% {
    fill: #eeeeee;
    stroke: #eeeeee;
  }
  50% {
    fill: #d0d0d0;
    stroke: #d0d0d0;
  }
  100% {
    fill: #eeeeee;
    stroke: #eeeeee;
  }
}
