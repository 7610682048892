.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 4px;
}
.text {
  width: 50%;
}
.perfIndex {
  width: 50%;
}
