.wrapper {
  box-sizing: border-box;
  height: 100%;
  padding: 12px 10px;
  margin: 0px 0px;
}

@media only screen and (max-width: 1600px) {
  .wrapper {
    padding: 6px 5px;
  }
}

.actions-container {
  width: calc(100% - 18px);
  left: 0;
  margin-top: 11px;
  padding-right: 9px;
  padding-left: 9px;
  display: flex;
  justify-content: flex-end;
  position: relative;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: 0.3s;
  height: 100%;
  border: var(--card-border);
  border-radius: var(--card-border-radius);
  background-color: var(--card-background-color);
  box-shadow: var(--box-shadow);
}

.title.has-actions {
  margin-top: 20px;
}

@media only screen and (max-width: 1600px) {
  .title.has-actions {
    margin-top: 6px;
  }
}

.content {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.content.has-title {
  height: calc(100% - 80px);
}

@media only screen and (max-width: 1600px) {
  .content.has-title {
    height: calc(100% - 45px);
  }
}
