.content {
  height: 100%;
  display: flex;
  box-shadow: 0px -2px 40px rgba(0, 0, 0, 0.1);
  transition: 0.3s;
  border-radius: var(--grid-item-border-radius);
}
.data-wrapper {
  margin: auto;
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 98%;
}
.row {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: 1%;
}
.button {
  margin-right: 0.5rem;
  margin-left: 0.5rem;
}
.error {
  color: var(--error-color);
}
