.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 9px 15px;
  gap: 10px;
  margin: auto;
  left: 50%;
  width: 800px;
}

.row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 10px;
}

.button {
  flex: 1;
  padding: 8px 8px;
  margin: 0px;
}
