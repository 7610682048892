.row {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
.column {
  display: flex;
  flex-direction: column;
  margin-top: 50px;
  width: 100%;
  height: 80%;
  justify-content: flex-start;
}
