.clock-group {
  display: flex;
  flex-direction: row;
  cursor: var(--mouse-cursor);
  position: sticky;
}

.time {
  padding: 0 0 0 10px;
}
@media screen and (max-width: 1600px) {
  .time {
    padding: 0 0 0 5px;
  }
}
