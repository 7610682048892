.error-container {
  background: #fafafa;
  border: 1px solid var(--table-outline-color);
  font-family: var(--font-family);
  font-style: var(--font-style);
  font-size: 38px;
  font-weight: 400;
  white-space: pre-line;
  color: black;
  position: absolute;
  top: 0;
  left: 0;
  width: 98%;
  height: 90%;
  z-index: 3;
}

.error-content {
  padding-top: 120px;
}

.error-heading {
  font-weight: 700;
}

.unity-canvas {
  width: 100%;
  height: 100%;
  border-radius: var(--card-border-radius);
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.unity-canvas.canvas-visible {
  opacity: 1;
}

.loader-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
  opacity: 1;
  transition: opacity 0.3s ease-out;
  width: 100%;
  height: 100%;
  background-color: #fafafa;
  border-radius: var(--card-border-radius);
}

.loader-wrapper.loader-hidden {
  opacity: 0;
  pointer-events: none;
}

.loader-wrapper svg {
  animation: rotate 0.7s linear infinite;
  transform-origin: center;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@media screen and (max-width: 1600px) {
  .error-container {
    font-size: 26px;
  }

  .error-content {
    padding-top: 90px;
  }
}