.table {
  display: inline-flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

.table-content {
  height: 100%;
  width: 257.5px;
}
.column {
  display: flex;
  flex-direction: column;
  height: 70%;
  width: 100%;
  align-items: center;
  margin-top: 30%;
  margin-left: 8px;
  margin-right: 8px;
}
