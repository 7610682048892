/*
  we want the site ID to be searchable when rendered within drop-down,
  but we also want to wrap it within brackets to make it visually distinct -
  so we wrap it in brackets using CSS and not in the text itself...
*/

.surround-with-parenthesis::before {
  content: "(";
}

.surround-with-parenthesis::after {
  content: ")";
}

.site-name-with-ellipsis {
  max-width: 240px;
  text-overflow: ellipsis;
  overflow: hidden;
}

@media only screen and (max-width: 1600px) {
  .site-name-with-ellipsis {
    max-width: 180px;
  }
}
