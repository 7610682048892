.header {
  height: 76px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
  box-shadow: none;
  background-color: transparent;
  color: var(--blue-color-primary);
}

.logo {
  height: 29px;
  margin-top: 2px;
  width: 108px;
}

.leftItems {
  display: flex;
  flex: 0.3;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  margin-left: var(--logo-margin-left-right);
}

.rightItems {
  display: flex;
  flex: 0.3;
  flex-direction: row;
  justify-content: flex-end;
  align-content: space-around;
  align-items: center;
  margin-right: 2rem;
}

.user {
  margin-left: 26px;
  mix-blend-mode: multiply;
  object-fit: cover;
}

.page-name {
  cursor: var(--mouse-cursor);
}
