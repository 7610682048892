.container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  color: var(--blue-color-primary);
}

.optionsContainer {
  display: flex;
  margin-left: 8px;
}

.label {
  position: relative;
  cursor: pointer;
  margin-left: 6px;
}

.readonly {
  cursor: not-allowed;
}

.input {
  display: none;
}

.optionSpan {
  font-size: 10px;
  height: 15px;
  width: 16px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color 0.3s;
}

.selected {
  background-color: #1976d2;
  color: white;
}

.unselected {
  background-color: #e0e0e0;
  color: #1976d2;
}
