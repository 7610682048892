.list {
  list-style-type: none;
  padding: 0px;
  margin: 16px;
  margin-top: 3px;
  margin-bottom: 0px;
}
.list-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
  padding: 7px 18px;
  cursor: pointer;
}
.list-item:hover {
  background-color: var(--list--item-on-hover-background-color);
}
.item-selected {
  margin-left: auto;
  align-self: flex-end;
}
