.pixels-container {
    position: absolute;
    top: 90px;
    left: 30px;
    width: 98%;
    height: 89%;
    z-index: 1;
    visibility: hidden;
}

.pixels-container.visible {
    visibility: visible;
}

@media screen and (max-width: 1600px) {
    .pixels-container {
        width: 95%;
        height: 85%;
        top: 70px;
        left: 25px;
    }
}