.table {
  border-radius: var(--grid-item-border-radius);
  transition: 0.3s;
}
.header {
  width: 100vw;
}
.inverter-icon,
.tracker-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 0.5rem;
}

.header-icon {
  display: flex;
  align-items: center;
  justify-content: center;
}

.site-name {
  text-align: left;
  justify-content: flex-start !important;
}

.site-name-header.in-search-mode {
  padding-right: 0;
}

.header-icon {
  margin-top: 3px;
  margin-right: 11px;
}
