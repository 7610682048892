.content {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 17px;
}

@media screen and (max-width: 1600px) {
  .content {
    gap: 10px;
  }
}

.grid-container {
  width: 100%;
  height: 95%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr auto;
  grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr;
}

.agri-table {
  grid-column: 1 / 2;
  grid-row: 1 / 7;
}

.actual-shading {
  grid-column: 2 /5;
  grid-row: 1 / 4;
}

.average-shading {
  grid-column: 5 /8;
  grid-row: 1 / 4;
}

.weather-chart {
  grid-column: 2 / 5;
  grid-row: 4 / 7;
}

.soil-chart {
  grid-column: 5 / 8;
  grid-row: 4 / 7;
}

.agri-sum {
  grid-column: 8 / 9;
  grid-row: 1 / 7;
}

.item-column {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-top: 4rem;
  align-items: center;
}
