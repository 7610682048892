.row {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.name {
  text-align: left;
  padding-left: 5%;
}

.circles-container {
  display: flex;
  justify-content: flex-end;
  padding-right: 5%;
}

.circle {
  width: 22px;
  height: 22px;
  line-height: 22px;
  border-radius: 30px;
  text-align: center;
  font-weight: var(--font-bold-weight);
  font-size: 11px;
  margin: 0 2px;
  color: white;
}

@media only screen and (max-width: 1600px) {
  .circle {
    width: 18px;
    height: 18px;
    line-height: 18px;
    border-radius: 25px;
    font-size: 10px;
  }
}

.low {
  background-color: var(--severity-low-color);
}

.medium {
  background-color: var(--severity-mild-color);
}

.high {
  background-color: var(--severity-high-color);
}
