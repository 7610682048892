.row {
  display: flex;
  height: 100%;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 8px;
}

.column {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.shading-percent {
  align-self: flex-start;
}
