.row {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 9px;
}
@media screen and (max-width: 1600px) {
  .row {
    gap: 7px;
  }
}
