.grid-container {
  height: 150vh;
  width: 100%;
  display: grid;
  grid-template-columns: 34% 32% 34%;
  grid-template-rows: 2fr 2fr 2fr 2fr 2fr 2fr 2fr 2fr;
}
.item1 {
  grid-column: 1 / 2;
  grid-row: 1 / 3;
}

.item4 {
  grid-column: 2 / 3;
  grid-row: 1 / 3;
}
.item2 {
  grid-column: 3 / 4;
  grid-row: 1 / 3;
}

.item3 {
  grid-column: 1/ 2;
  grid-row: 3 /5;
}

.item5 {
  grid-column: 2/3;
  grid-row: 3/5;
}
.item6 {
  grid-column: 3/4;
  grid-row: 3/5;
}
