.row {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.right-arrow {
  padding-left: 2%;
}

.current-state {
  padding-left: 5%;
}
