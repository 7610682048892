.version {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  list-style: none;
  border: 1px solid var(--blue-color-primary);
  border-radius: var(--grid-item-border-radius);
  justify-content: space-evenly;
  margin: 0.5em 1em;
  padding-left: 5px;
  padding-right: 5px;
  padding-bottom: 5px;
}

.description {
  width: 100%;
  border-radius: var(--grid-item-border-radius);
}

.uploaded-by {
  font-size: 10px;
}
