.grid-container {
  width: 100%;
  height: 100vh;
  display: grid;
  cursor: default;
  grid-template-columns: [left] 25% 37.5% 37.5%[right] auto;
  grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
}

.data-point-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 100%;
}

.group-list {
  place-content: center;
}

.cards-row1 {
  grid-column: 1 / 2;
  grid-row: 1 /3;
}

.cards-row2 {
  grid-column: 1 / 2;
  grid-row: 3 / 5;
}

.cards-row3 {
  grid-column: 1 / 2;
  grid-row: 5 / 7;
}

.cards-row4 {
  grid-column: 1 / 2;
  grid-row: 7 / 9;
}

.production-chart {
  grid-column: 2 / 3;
  grid-row: 1 / 5;
}

.weather-chart {
  grid-column: 2 / 3;
  grid-row: 5 / 9;
}

.daily-production-chart {
  grid-column: 3 / 4;
  grid-row: 1 / 5;
}

.performance-chart {
  grid-column: 3 / 4;
  grid-row: 5/ 9;
}

.daily-comulitive {
  grid-column: 4 / 5;
  grid-row: 1 / 10;
}

.footer {
  grid-column: 1 / 5;
  grid-row: 9 / 10;
  justify-content: center;
}

@media only screen and (max-width: 1600px) {
  .grid-container {
    height: calc(100vh - 40px);
  }
}
