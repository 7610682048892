.circle {
  border: 1px solid;
  width: 29px;
  line-height: 30px;
  border-radius: 50%;
  text-align: center;
}

.low {
  border-color: var(--severity-low-color);
}

.mild {
  border-color: var(--severity-mild-color);
}

.high {
  border-color: var(--severity-high-color);
}

.text {
  padding: 1.5px 7px;
  padding-top: 2.5px;
}
