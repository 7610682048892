.grid-container {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 83% 17%;
}

.table-card {
  grid-column: 1 / 2;
  overflow-y: auto;
}

.side-card {
  grid-column: 2 / 3;
}
