.container {
  display: flex;
  justify-content: flex-start;
  font-size: 14px;
  font-weight: var(--font-weight);
  color: var(--blue-color-primary);
  list-style-type: none;
  white-space: nowrap;
  flex-direction: column;
  letter-spacing: 0.05em;
}

.container-title {
  margin-bottom: 10px;
}

.list {
  padding-inline-start: 0;
  align-content: space-between;
  padding: 0;
  margin: 0;
}

.inline-row {
  display: inline-flex;
  margin-bottom: 0px;
}

.inline-icon {
  margin-top: -1.5px;
  margin-left: 4px;
}

.row {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.text-column {
  flex-direction: column;
  margin-right: 10px;
}

.icon-column {
  flex-direction: column;
  margin-top: -1.75px;
}

.timestamp {
  font-size: 10px;
}
