.content {
  display: flex;
  flex-direction: column;
  padding-right: 6px;
  box-sizing: border-box;
  gap: 2rem;
}
.overflow {
  width: 100%;
  overflow: auto;
  height: 80vh;
}
