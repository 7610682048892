.data-wrapper {
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 1rem;
  margin-top: 1rem;
  width: 50%;
}
.row {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin: auto;
  justify-content: space-between;
  align-items: center;
}
.row.partial {
  width: 50%;
}
.loading-status-row {
  margin-bottom: 10px;
}
.loading-status-text {
  font-weight: 600;
}
.status-skeleton {
  display: inline-block;
  width: 50%;
}
