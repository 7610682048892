.container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.toolbar {
  display: flex;
  justify-content: center;
  padding: 8px;
  background-color: #f9f9f9;
  border-top: 1px solid #ddd;
}

.error-cell {
  background-color: rgba(255, 0, 0, 0.1);
  color: red;
}
