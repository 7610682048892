.content-container {
  flex-direction: column;
  display: flex;
  font-weight: var(--font-weight);
}

.header-container {
  flex-direction: row;
  display: flex;
}

.exclamation-mark {
  width: 16px;
  height: 16px;
  line-height: 16px;
  border-radius: 5px;
  text-align: center;
  font-size: 14px;
  color: white;
  margin-right: 5px;
  margin-top: 5px;
}

@media only screen and (max-width: 1600px) {
  .exclamation-mark {
    width: 13px;
    height: 13px;
    line-height: 13px;
    font-size: 12px;
    margin-top: 3px;
  }
}

.multiple-list-items {
  padding-inline-start: 27px;
  list-style-type: decimal;
}

.single-list-item {
  padding-inline-start: 4px;
  list-style-type: none;
}

.low {
  background-color: var(--severity-low-color);
}

.medium {
  background-color: var(--severity-mild-color);
}

.high {
  background-color: var(--severity-high-color);
}
