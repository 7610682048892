.list-items {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: default;
  justify-content: center;
  width: 100%;
  height: 100%;
  list-style-type: unset;
  line-height: 1;
}
.two-lines {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  display: flex;
  flex-direction: column;
}
