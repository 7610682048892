.container {
  height: 100%;
  width: 100%;
  overflow: auto;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  padding-top: 44px;
}

@media only screen and (max-width: 1600px) {
  .container {
    padding-top: 0;
  }
}

.panels-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 10px;
}
