.custom-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: var(--overlay-color);
  z-index: 1;
}

.overlay-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  color: var(--overlay-text-color);
}

.icon {
  cursor: pointer;
}

.icon-close {
  top: 0;
  right: 0;
  animation: fadeOut 0.5s ease-in-out;
  background-color: var(--transparent-color);
}

@keyframes fadeOut {
  from {
    opacity: 1;
    transform: translate(0, 0) scale(1);
  }

  to {
    opacity: 0.2;
    transform: translate(-500%, -190%) scale(0.2);
  }
}