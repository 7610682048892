.wrapper {
  height: 80vh;
  width: 100%;
  margin: auto;
}

.sprinklers-control {
  display: flex;
  gap: 8px;
  margin-left: 30px;
  margin-top: 40px;
}
