.grid-container {
  margin: 0 1rem;
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 30% 70%;
  grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  box-shadow: none;
  padding-bottom: 2rem;
}

.time-query {
  grid-column: 1 / 2;
  grid-row: 1 / 2;
}
.siteTag-query {
  grid-column: 1 / 2;
  grid-row: 2 / 11;
}
.show-button {
  grid-column: 1 / 2;
  grid-row: 8 / 9;
  margin-bottom: 1rem;
}
.trend-card {
  grid-column: 2 / 3;
  grid-row: 2 / 11;
}
.row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  margin: auto 0;
}
.hide {
  display: none;
}
