.icon {
  cursor: pointer;
}

.dialog{
  text-align: center;
  display:flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 16px;
}

.dialog-actions {
  display: flex;
  justify-content: center;
  height: 30px;
}

@media screen and (max-width: 1600px) {
  .dialog-actions {
    max-height: 23px;
  }
}

.header {
  font-weight: 600;
  max-lines: 1;
}
