.sprinkler-control-container {
  width: 20%;
}

.sprinkler-control-content {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 20px 30px;
}

@media only screen and (max-width: 1600px) {
  .sprinkler-control-content {
    padding: 15px 20px;
  }
}

.sprinkler-state {
  padding-top: 0px;
  padding-bottom: 30px;
}

.sprinkler-control-column-items {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
