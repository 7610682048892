.dialog-content {
  width: 90%;
  min-height: 350px;
  display: flex;
  flex-direction: column;
}

.tabs-container {
  flex-grow: 1;
}

.form-container {
  display: flex;
  flex-direction: row;
  gap: 1rem;
}

.dialog-title {
  color: var(--blue-color-primary);
}

.error-message {
  color: red;
  margin-top: 5px;
}

.error-message-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.dialog-container {
  position: relative;
}
