.app-bar {
  box-shadow: none;
  background-color: transparent;
  color: var(--blue-color-primary);
}

.tool-bar {
  margin-left: var(--logo-margin-left-right);
  margin-right: var(--logo-margin-left-right);
  padding: 0;
  width: calc(100% - 2 * var(--logo-margin-left-right));
}

.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.left-components {
  flex: 1;
  justify-content: flex-start;
  overflow: hidden;
}

.right-components {
  justify-content: flex-end;
  align-items: center;
  gap: 25px;
}

@media screen and (max-width: 1600px) {
  .right-components {
    gap: 15px;
  }
}

.logo {
  margin: 0;
}

.sites-dropdown {
  margin-left: 1em;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@media screen and (max-width: 1600px) {
  .sites-dropdown {
    margin-left: 0.3em;
  }
}

.data-points {
  flex: 1;
  overflow: hidden;
  justify-content: flex-start;
  margin-left: 5em;
}
@media screen and (max-width: 1600px) {
  .data-points {
    margin-left: 1em;
  }
}

.system-status {
  display: flex;
}

.emergency {
  width: 120px;
}

@media screen and (max-width: 1600px) {
  .emergency {
    width: 80px;
  }
}

.time-and-user {
  width: 205px;
  white-space: nowrap;
  justify-content: space-between;
}

@media screen and (max-width: 1600px) {
  .time-and-user {
    width: 155px;
  }
}

.diffuse-optimization-place-holder {
  width: 52px;
}

@media screen and (max-width: 1600px) {
  .diffuse-optimization-place-holder {
    width: 32px;
  }
}
