@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto+Mono:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

input {
  font-family: var(--font-family);
  font-weight: 400;
}

.App {
  text-align: center;
  width: 100vw;
  height: 100vh;
  padding: 0rem;
  box-sizing: border-box;
  background-repeat: no-repeat;
  background-size: cover;
  overflow-y: hidden;
  overflow-x: hidden;
}

.App.small-screen {
  display: none;
}

.App.small-screen-show {
  display: none;
}

.App.small-screen + .App.small-screen-show {
  display: block;
  display: flex;
  justify-content: center;
  align-items: center;
}

:root {
  --breakpoint-xs: 1300;
  --white: white;
  --blue-color-primary: #1f3171;
  --error-color: #f17a7a;

  --warning-color: #f9c438;
  --ok-color: #7cdeaf;
  --error-color-validation: #f17a7a;
  --input-adornment-focused-color: #1377e7;

  --severity-high-color: #ff4e4e;
  --severity-mild-color: #ff9200;
  --severity-low-color: #f9c438;
  --undefined-color: #ccc;

  --disable-color: #c2c6d4;
  --background-color: #fcfcfc;
  --table-on-hover-color: #ededed;
  --table-outline-color: #d2d8de;
  --animate-button-start-color: #1377e7;
  --animate-button-end-color: #0c61c0;
  --blue-button-background-color: #1377e7;
  --button-hover-color: #e2f2ff;
  --button-text-color: white;
  --filled-button-on-hover-color: #0d55a5;
  --blue-spinner: rgb(42, 113, 208);
  --transparent-color: rgba(0, 0, 0, 0);
  --overlay-color: rgba(0, 0, 0, 0.6);
  --overlay-text-color: white;
  --card-background-color: #ffffff;
  --grey-tooltip: #595959;
  --chart-grid: #ededed;
  --chart-vertical-line: var(--overlay-color);
  --chart-tooltip-background-color: #fff;

  --font-family: "Poppins", sans-serif;
  --font-style: normal;
  --font-bold-weight: 500;
  --font-weight: 400;

  --grid-item-border-radius: 15px;
  --border-radius-range-date-picker: 40px;
  --border-radius-button: 40px;
  --border-radius-dropdown: 20px;

  --card-margin-title-top: 42px;
  --card-margin-content-bottom: 42px;
  --card-border: 1px solid #ededed;
  --card-border-radius: 25px;

  --tooltip-border-radius: 25px;
  --tooltip-box-shadow: 2px 3px 22.3px 0px rgba(217, 217, 217, 1);

  --large-icon-size: 64px;
  --medium-icon-size: 45px;

  --mouse-cursor: default;
  --mouse-cursor-hover: pointer;

  --font-size-button: 14px;
  --blue-thin-border: 1px solid #2c3f81;

  --box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.04);
  --drawer-width: 100px;
  --list--item-on-hover-background-color: #d9d9d933;

  --logo-margin-left-right: 20px;

  --nav-list-item-color: #2c3f81;
  --nav-active-list-item-color: white;
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

/* Background of the Scrollbar Track */
::-webkit-scrollbar-track {
  background-color: #f5f5f5;
}

/* Style of the Scrollbar Thumb */
::-webkit-scrollbar-thumb {
  background-color: #c0c0c0;
  border-radius: 6px;
}

/* Style when Hovering over the Scrollbar Thumb */
::-webkit-scrollbar-thumb:hover {
  background-color: #a0a0a0;
}

.third-height {
  position: absolute;
  top: 33.33%;
  width: 100%;
  text-align: center;
  opacity: 0;
  animation-name: fade-in-out;
  animation-duration: 1.5s;
  animation-iteration-count: infinite;
}

@keyframes fade-in-out {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@media only screen and (max-width: 1600px) {
  :root {
    --card-border-radius: 20px;
    --card-margin-title-top: 14px;
    --card-margin-content-bottom: 14px;
    --logo-margin-left-right: 9.5px;
    --drawer-width: 84px;
  }
}
