.field-state-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  position: relative;
}

.row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  gap: 50px;
  width: 100%;
  height: 100%;
}
@media screen and (max-width: 1600px) {
  .row {
    gap: 30px;
  }
}
