.form {
  display: flex;
  flex-direction: column;
  width: 24rem;
  padding: 1rem;
}
.field {
  margin-bottom: 1em;
}
.add-button-container {
  display: flex;
  justify-content: center;
}
