.column {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0;
  padding: 0;
}
.container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-end;
}
.circle-btn {
  border-radius: 50%;
  height: 14px;
  width: 14px;
  cursor: pointer;
  margin-bottom: 3px;
}
.item {
  width: 33%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.color-text {
  color: var(--blue-color-primary);
}
