.content {
  display: flex;
  flex-direction: column;
  height: 700px;
  width: 100%;
}
.row {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  flex-wrap: wrap;
  justify-content: space-evenly;
}
