.data-point-container {
  display: flex;
  cursor: default;
  align-items: center;
  color: var(--blue-color-primary);
  white-space: nowrap;
  gap: 2px;
}

@keyframes blink {
  90% {
    color: var(--error-color);
  }
  10% {
    color: transparent;
  }
}

.blinking-class {
  animation: blink 1s infinite;
  color: var(--error-color);
}
