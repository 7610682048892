.popup {
  z-index: 1;
  position: fixed;
  width: 50%;
  height: 50%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  background-color: var(--transparent-color);
}
.popup-is-open {
  position: absolute;
  left: 10%;
  right: 10%;
  top: 10%;
  bottom: 10%;
  margin: auto;
  border-radius: 20px;
  overflow: hidden;
  padding: 1%;
  background-color: var(--white);
  box-shadow: var(--tooltip-box-shadow);
}
.popup-exit-button {
  margin-right: 2%;
  border-radius: var(--border-radius-button);
  float: right;
}
.popup-content {
  width: 50%;
  margin: auto;
  height: 60%;
  font-size: small;
  display: inline-flexbox;
}
.header-items {
  display: flex;
  padding: 0.5rem;
  align-items: flex-start;
  z-index: 10;
  font-size: large;
  color: var(--blue-color-primary);
}
