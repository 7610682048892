.status-icons-list {
  list-style-type: none;
  display: flex;
  flex-direction: row;
  margin: 0;
  padding: 0;
  gap: 25px;
  justify-content: space-between;
  align-items: center;
}

@media screen and (max-width: 1600px) {
  .status-icons-list {
    gap: 15px;
  }
}
.container {
  display: flex;
  align-items: center;
  position: relative;
}
