.date-selector {
  display: inline-flex;
  padding: 0.5rem;
  align-items: center;
  z-index: 10;
}
.checkbox {
  margin-left: 10px;
  padding: 0;
}
