.dialog-header {
  display: flex;
  justify-content: flex-end;
  padding: 8px;
  color: var(--white);
}

.result-title {
  display: flex;
  justify-content: flex-start;
  margin: 0 1em 0 1em;
  color: var(--blue-color-primary);
}

.results {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 28rem;
  height: 35rem;
  overflow-y: auto;
  overflow-x: hidden;
  margin: 1em;
  margin-top: 0;
  padding: 1em;
  background-color: var(--blue-color-primary);
}

.result-tracker {
  display: flex;
  flex-direction: column;
  width: 100%;
  border-bottom: 1px solid var(--white);
  margin: 1em;
}

.result-item {
  flex: 0.4;
  padding-left: 0.5em;
  color: var(--white);
}
