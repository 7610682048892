.pop-up-container {
  padding: 0px 4px;
  position: absolute;
  top: 2px;
  right: -19px;
}

@media screen and (max-width: 1600px) {
  .pop-up-container {
    right: -16px;
  }
}

.in-override {
  right: 16px;
  animation: flash 1.5s infinite;
  border: 0px;
  color: var(--error-color);
}

@keyframes flash {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
}
