.trends-table {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.container {
  width: 100%;
  height: 100%;
}
