.item-wrapper {
  padding-left: 5px;
  width: fit-content;
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: center;
}

.option {
  padding: 5px;
  width: 100%;
  border-radius: var(--border-radius-range-date-picker);
  border-color: var(--blue-color-primary);
  color: var(--blue-color-primary);
}

.separator-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.separator {
  margin-top: -5px;
  margin-left: 8px;
}

.option:hover {
  border-color: var(--blue-button-background-color);
  color: var(--blue-color-primary);
}

.focused {
  border-color: var(--blue-color-primary);
  color: var(--blue-color-primary);
}

.option :global(.ant-picker-input) {
  padding-left: 15px;
}

.option :global(.ant-picker-input > input) {
  font-size: 18px;
  max-width: 150px;
  font-weight: var(--font-bold);
  font-family: var(--font-family);
}

.option :global(.ant-picker-input > input::placeholder) {
  color: var(--blue-color-primary);
}

.option:hover {
  border-color: var(--blue-color-primary);
}

.option :global(.ant-picker-cell) {
  color: var(--blue-color-primary);
}

.option :global(.ant-picker-header) {
  color: var(--blue-color-primary);
}

.option :global(.ant-picker-suffix) {
  margin-left: -8px;
  margin-top: -4px;
}

.option
  :global(
    .ant-picker-dropdown
      .ant-picker-time-panel-column
      > li.ant-picker-time-panel-cell
      .ant-picker-time-panel-cell-inner
  ) {
  color: var(--blue-color-primary);
}

.option :global(.ant-picker-dropdown .ant-picker-content th) {
  color: var(--blue-color-primary);
}
