.row {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.charging-state {
  width: 70%;
}

.right-border {
  height: 100%;
  background-color: var(--table-outline-color);
  width: 1px;
}

.charging-percent {
  width: 30%;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}

.battery-icon {
  flex: 0.3;
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

.charging-percent-text {
  flex: 0.3;
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  line-height: 25px;
}
