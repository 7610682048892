:root {
  --diam: 4rem;
  --border-width: 0.4vw;
  --z-index-overlay: 2;
}

.loader-spinner {
  border: var(--border-width) solid var(--transparent-color);
  border-radius: 50%;
  border-top: var(--border-width) solid var(--blue-spinner);
  background-color: var(--transparent-color);
  border-bottom: var(--border-width) solid var(--yellow);
  width: var(--diam);
  height: var(--diam);
  animation: spin 2s linear infinite;
  position: absolute;
  left: calc(50% - 0.5 * var(--diam));
  top: calc(50% - 0.5 * var(--diam));
  z-index: var(--z-index-overlay);
}

.loader-container {
  width: 100%;
  height: 100%;
  position: relative;
  z-index: var(--z-index-overlay);
}

.color-overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  transition: opacity 100ms linear;
  background: var(--overlay-color);
  opacity: 0.6;
  z-index: var(--z-index-overlay);
}

.color-overlay:hover {
  opacity: 0.3;
  z-index: var(--z-index-overlay);
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
