.tooltip-info-list {
  margin: 0;
  padding: 0;
  font-weight: var(--font-weight);
  color: var(--blue-color-primary);
  list-style-type: none;
  white-space: nowrap;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  flex-wrap: "wrap";
  align-items: flex-start;
}

.title {
  font-size: 16px;
}

.text {
  font-size: 13px;
}
