.component-ids-column {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  cursor: default;
  padding: 0 5%;
}

.device-prefix {
  font-weight: normal;
}

.added-device {
  color: red;
  background-color: rgba(255, 0, 0, 0.05);
  font-weight: bold;
}

.removed-device {
  color: green;
  background-color: rgba(0, 255, 0, 0.07);
  font-weight: bold;
}
