.option {
  align-items: center;
  justify-content: space-between;
  color: var(--blue-color-primary);
  text-transform: none;
  margin: 0.2rem;
  flex: 1;
}
.form {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
  width: 95%;
  margin: auto;
  padding-top: 0.5em;
}
.lines-box {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
}
.table {
  max-height: 32rem;
  height: 100%;
  width: 95%;
  margin: 0.5rem;
  overflow-y: auto;
  box-sizing: border-box;
}
@keyframes colorChange {
  0% {
    background-color: var(--animate-button-start-color);
  }
  100% {
    background-color: var(--animate-button-end-color);
  }
}
.animateButton {
  animation: colorChange 0.4s ease-in-out infinite alternate;
}
