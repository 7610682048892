.table-data-point-container {
  display: flex;
  flex-direction: column;
}

.header {
  display: flex;
  justify-content: center;
  flex-direction: row;
}

.value {
  margin-top: -6px;
}

.title {
  display: flex;
  margin-bottom: 0px;
  align-items: center;
}

.unit {
  margin-top: -6px;
}

.table-data-point-body {
  display: flex;
  flex-direction: column;
}
