.issues-section {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  height: fit-content;
  overflow-y: auto;
  gap: 2rem;
  border-left: 0.3rem solid var(--blue-button-background-color);
  border-radius: 8px;
}
.collapse {
  width: 100%;
  height: 100%;
  background-color: var(--white);
  border: 1px solid var(--table-outline-color);
}

.panel-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  color: var(--blue-color-primary);
  border-bottom: 0px;
}

.row {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  align-content: flex-start;
  justify-content: flex-start;
}
.title-info {
  color: var(--blue-color-primary);
  text-align: start;
}
.panel {
  margin: 0.2rem;
}
.no-issues-info {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
